// The entire purpose of this module is to import several libraries and expose them as globals. Namely: jQuery, Angular.JS, and Lodash.
//
// Angular.JS can only be imported once in all of our application code, as a result, we can't really get away with
// importing it in our main front-royal bundle. This is because our code gets split into multiple chunks, and we don't
// have any sort of guarantees around execution order, so we can't be sure that a given chunk that attempts to access
// the global `angular` object will be executing after the chunk that actually imports and exposes it.
//
// So we put Angular.JS in its own module/workspace/bundle with its own entrypoint in the Webpack config, and ensure we load
// the chunks from this bundle before any of our application code.
//
// We should be able to safely remove this module, and Yarn workspace, in isolation when we no longer have any Angular.JS code.
// Note that the core-js/stable and modernizr imports will likely need to be moved to the main front-royal bundle (probably
// lodash too, unless we've done the work to get rid of the `_` global).

// eslint-disable-next-line no-unused-vars
import { $, angular } from 'jqueryAndAngular';
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line lodash-fp/use-fp
import _ from 'lodash';
import 'core-js/stable';
import './static/modernizr.custom.91603';

// On web, window.ENDPOINT_ROOT is already set in app/views/shared/_head_scripts.html.erb.
// On mobile, this won't yet be set since we don't have access to that partial, so we rely
// on Webpack to set an appropriate value for window.CORDOVA.endpointRoot at build time and
// assign that value to window.ENDPOINT_ROOT here.
// This needs to happen before anything else, even before the app is bootsrapped, so that
// mobile clients can talk to the backend.
// FIXME: Find a better way to do this, it shouldn't live in vendor/libraries/index.js, but this
// needs to happen before ANY of the front-royal code is executed.
// If we could get rid of the WINDOW.ENDPOINT_ROOT = `https://${QUANTIC_CHINA_APP_DOMAIN}`;
// assignment in vendor/front-royal/modules/FrontRoyalAngular/angularModule/index.js, we could
// simply change webpack/webpack.mobile.config.js to set window.ENDPOINT_ROOT via DefinePlugin directly.
if (window.CORDOVA?.endpointRoot) window.ENDPOINT_ROOT = window.CORDOVA.endpointRoot;
